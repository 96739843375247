<template>
  <welfare-project-listener-base-dialog
    :id="id"
    :project-id="projectId"
    dialog-title="Volume per Period Listener"
    type="volumePeriod"
  >
    <template v-slot:activator="{open}">
      <slot :open="open" />
    </template>
    <div>
      <v-select
        v-model="direction"
        :items="[{text: 'Greater than', value: 'greater'}, {text: 'Less than', value: 'less'}]"
        label="Alert if volume is..."
        :rules="directionRules"
      />
      <v-text-field
        v-model="amount"
        :rules="amountRules"
        label="Alert Volume"
      />
      <v-select
        v-model="periodType"
        :items="[
          {text: 'Last x Days', value: 'day'},
          {text: 'Last x Hours', value: 'hour'},
          {text: 'Last x Minutes', value: 'minute'},
        ]"
        :rules="periodTypeRules"
        label="Over Period Type"
      />
      <v-text-field
        v-model="periodAmount"
        :rules="periodAmountRules"
        label="Period Amount (x)"
      />
    </div>
  </welfare-project-listener-base-dialog>
</template>

<script>
import WelfareProjectListenerBaseDialog from "@/components/Welfare/Project/Listener/BaseDialog";

export default {
  components: {WelfareProjectListenerBaseDialog},
  name: "WelfareProjectListenerVolumePeriodDialog",
  props: {
    id: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelField: ["amount", "direction", "periodAmount", "periodType"],
      amount: "",
      amountRules: [
        v => !!v || "Please enter an amount",
        v => !isNaN(v) || "Please enter a numerical amount",
      ],
      direction: "",
      directionRules: [
        v => !!v || "Please select a direction",
      ],
      periodAmount: "",
      periodAmountRules: [
        v => !!v || "Please enter an amount",
        v => !isNaN(v) || "Please enter a numerical amount",
      ],
      periodType: "",
      periodTypeRules: [
        v => !!v || "Please select a type",
      ],
    };
  },
};
</script>
