const { Octokit } = require("@octokit/rest");
const owner = "fundamentalmedia"
import store from '@/store.js'


if(store.state && !store.state.gh_token){
    (async () => {await store.dispatch('gh_setup')})();
}

const octokit = new Octokit({
    auth: store.state.gh_token || process.env.VUE_APP_GITHUB_TOKEN,
    userAgent: 'myApp v1.2.3',
    previews: ['jean-grey', 'symmetra'],
    baseUrl: 'https://api.github.com',
})

const listOfPRs = async (repo)=> {
    return octokit.rest.pulls.list({
        owner: owner,
        repo: repo,
        state: 'all'
        });
} 

const repos = (org="fundamentalmedia") =>{
    return octokit.rest.repos.listForOrg({
        org,
        per_page:100
      });
}

const repoInfo = async (repo,) =>{
    return octokit.request(`GET /repos/${owner}/${repo}/git/refs/heads`)
}

const prStatus = async (repo, pull_number)=>{
    return octokit.request('GET /repos/{owner}/{repo}/pulls/{pull_number}', {
        owner,
        repo: repo,
        pull_number: pull_number
    })
} 

const approvalStatus = async (repo, pull_number)=>{
    return octokit.request('GET /repos/{owner}/{repo}/pulls/{pull_number}/reviews', {
        owner,
        repo: repo,
        pull_number: pull_number
    })
} 

const branchList = async (repo)=>{
    return octokit.request('GET /repos/{owner}/{repo}/branches', {
        owner,
        repo: repo,
        per_page: 100
    })
}

const getBranch = async (repo, branch)=>{
    return octokit.rest.repos.getBranch({
        owner,
        repo,
        branch,
    });
}

const commitList = async (repo)=>{
    return octokit.request('GET /repos/{owner}/{repo}/commits', {
        owner,
        repo: repo
    })
}

const createPR = async ( repo, head, base) =>{
    const octokit = new Octokit({
        auth: store.state.gh_token || process.env.VUE_APP_GITHUB_TOKEN,
        userAgent: 'myApp v1.2.3',
        previews: ['jean-grey', 'symmetra'],
        baseUrl: 'https://api.github.com',
    })
    return octokit.rest.pulls.create({owner, repo, head, base});
}

const createBranch = async ( repo, branchName, sha) =>{
    const octokit = new Octokit({
        auth: store.state.gh_token || process.env.VUE_APP_GITHUB_TOKEN,
        userAgent: 'myApp v1.2.3',
        previews: ['jean-grey', 'symmetra'],
        baseUrl: 'https://api.github.com',
    })
    return octokit.request(`POST /repos/${owner}/${repo}/git/refs`, {
        "ref": `refs/heads/${branchName}`,
        "sha": sha
    })
}

const rev = async ( repo, pull_number) =>{
    const octokit = new Octokit({
        auth: store.state.gh_token || process.env.VUE_APP_GITHUB_TOKEN,
        userAgent: 'myApp v1.2.3',
        previews: ['jean-grey', 'symmetra'],
        baseUrl: 'https://api.github.com',
    })
    
    return octokit.request(`GET /repos/{owner}/{repo}/pulls/{pull_number}/requested_reviewers`, {
        owner: owner,
        repo: repo,
        pull_number: pull_number
      })
} 

export {listOfPRs, repos, createPR, createBranch, repoInfo, prStatus, approvalStatus, branchList, getBranch, commitList, rev}