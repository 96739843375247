<template>
  <page-upsert-dialog
    ref="dialog"
    :error="error"
    :mode="mode"
    :title="title"
    @clear="clear"
    @save="save"
  >
    <template v-slot:activator="{open}">
      <slot :open="open" />
    </template>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Team Member Name"
      />
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="Team Member Email"
      />
    </v-form>
  </page-upsert-dialog>
</template>

<script>
import {db} from "@/firestore";
import PageUpsertDialogBase from "@/components/Page/UpsertDialog.base";

export default {
  extends: PageUpsertDialogBase,
  name: "WelfareTeamMemberUpsertDialog",
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelField: ["email", "name"],
      email: "",
      emailRules: [
        v => !!v || "Please enter the team members email address",
        v => !!v.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ||
          "Please enter a valid email address",
      ],
      name: "",
      nameRules: [
        v => !!v || "Please enter a team member name",
      ],
      title: "Team Member",
    };
  },
  methods: {
    create(resolve) {
      db.collection("welfare-team").
        doc(this.teamId).
        collection("member").
        add(this.modelData).
        then((document) => {
          resolve(document.id)
        });
    },
    edit(resolve) {
      db.collection("welfare-team").
        doc(this.teamId).
        collection("member").
        doc(this.id).
        update(this.modelData).
        then(() => {
          resolve(this.id)
        });
    },
    lookup(resolve, reject) {
      db.collection("welfare-team").
        doc(this.teamId).
        collection("member").
        doc(this.id).
        get().
        then((data) => {
          if (!data.exists) {
            reject();
          } else {
            resolve(data.data());
          }
        });
    },
  },
};
</script>
