import PageUpsertDialogBase from "@/components/Page/UpsertDialog.base";
import PageUpsertDialog from "@/components/Page/UpsertDialog";

export default {
  extends: PageUpsertDialogBase,
  name: "PageUpsertDialogBase",
  components: {PageUpsertDialog},
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      createData: {},
      error: null,
      mode: "loading",
      modelField: [],
      title: "Untitled",
    };
  },
  computed: {
    modelData() {
      const data = {};
      for (const field of this.modelField) {
        data[field] = this[field];
      }
      return data;
    },
  },
  mounted() {
    if (this.id) {
      new Promise((resolve, reject) => {
        this.lookup(resolve, reject);
      }).then(data => {
        for (const key of Object.keys(data)) {
          if (this.modelField.includes(key)) {
            this[key] = data[key];
          }
        }
        this.mode = "edit";
      }).catch(() => {
        this.error = `Cannot find a ${this.title} in the system under the ID: ${this.id}`;
      });
    } else {
      this.mode = "create";
      for (const field of this.modelField) {
        this.createData[field] = this[field];
      }
    }
  },
  methods: {
    clear() {
      if (this.mode === "create") {
        this.$refs.form.reset();
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.error = null;
        if (!["create", "edit"].includes(this.mode)) {
          this.error = `Cannot save unknown mode: ${this.mode}`;
          return;
        }
        new Promise(resolve => {
          this[this.mode](resolve);
        }).then(data => {
          this.$emit("saved", data);
          if (this.$refs.dialog) this.$refs.dialog.close();
          this.clear();
        })
      } else {
        this.error = `Please correct the errors with your form`;
      }
    },
  },
};
