<template>
  <div>
    <slot
      name="activator"
      :open="open"
    />
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="800"
    >
      <v-card :disabled="mode === 'loading'">
        <v-card-title v-if="mode === 'loading'">
          Loading...
        </v-card-title>
        <v-card-title v-if="mode === 'create'">
          Add {{ title }}
        </v-card-title>
        <v-card-title v-if="mode === 'edit'">
          Update {{ title }}
        </v-card-title>
        <v-card-text class="mt-6">
          <v-alert
            v-if="error"
            type="error"
          >
            {{ error }}
          </v-alert>
          <div class="mx-auto" style="max-width: 500px;">
            <slot />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mr-4"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="$emit('save')"
          >
            {{ mode === "create" ? `Add ${title}` : `Update ${title}` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PageUpsertDialog",
  props: {
    error: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.$emit('clear')
      this.dialog = false;
    },
    open() {
      // Hack to allow dialogs to be nested in menus
      setTimeout(() => this.dialog = true, 0)
    },
  },
};
</script>
