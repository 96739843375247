<template>
  <page-loader v-if="!project || !teamList || !listener"/>
  <v-row v-else>
    <v-col :xl="3" :lg="4" :md="5" :sm="12">
      <v-card>
        <v-card-title>
          Welfare Listener: {{ project.name }} / {{ listener.name }}
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Listener</v-list-item-title>
              <v-list-item-subtitle>{{ listener.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Listener Code</v-list-item-title>
              <v-list-item-subtitle>{{ listener.code }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Listener Type</v-list-item-title>
              <v-list-item-subtitle>{{ listenerTypeFriendly }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Project</v-list-item-title>
              <v-list-item-subtitle>{{ project.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Team</v-list-item-title>
              <v-list-item-subtitle>{{ teamName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Stale Alert</v-list-item-title>
              <v-list-item-subtitle>{{ stalePeriod }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer/>
          <component :is="dialogComponent" :id="listenerID" :project-id="projectID">
            <template v-slot="{open}">
              <v-btn
                @click="open"
              >
                <v-icon class="mr-2" small>fal fa-pencil</v-icon>
                Edit Listener
              </v-btn>
            </template>
          </component>
        </v-card-actions>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          Implementation
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>URL</v-list-item-title>
              <v-list-item-subtitle style="white-space: normal">https://europe-west2-digital-intelligence-208511.cloudfunctions.net/shepherdWelfareListener</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Method</v-list-item-title>
              <v-list-item-subtitle>POST</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Body</v-list-item-title>
              <prism-editor class="shepCodeEditor" v-model="implementationBody" :highlight="highlighter"
                            line-numbers readonly/>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="implementationBodyAlternative">
            <v-list-item-content>
              <v-list-item-title>Alternative Body</v-list-item-title>
              <prism-editor class="shepCodeEditor" v-model="implementationBodyAlternative" :highlight="highlighter"
                            line-numbers readonly/>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col :xl="9" :lg="8" :md="7" :sm="12">
      <v-card>
        <v-card-title
          :class="{green: listener.status === 'ok', red: listener.status !== 'ok'}"
          class="white--text"
        >
          Status: {{ listener.status }}
        </v-card-title>
        <v-card-text>
          <v-list v-if="listener.type === 'errorGroup'">
            <v-list-item v-for="(error, i) of errorGroup" :key="`error${i}`">
              <v-list-item-content>
                <v-list-item-title>{{ error.id }}</v-list-item-title>
                <v-list-item-subtitle style="white-space: normal">{{ error.data }}</v-list-item-subtitle>
                <v-list-item-subtitle style="white-space: normal">{{ new Date(error.lastSeen.seconds * 1000) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <graph-highcharts
            v-else
            :data="graphData"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {db} from "@/firestore";
import PageLoader from "@/components/Page/Loader";
import WelfareProjectListenerErrorGroupDialog
  from "@/components/Welfare/Project/Listener/ErrorGroup";
import WelfareProjectListenerSuccessFailRatioDialog
  from "@/components/Welfare/Project/Listener/SuccessFailRatioDialog";
import WelfareProjectListenerVolumePeriodDialog
  from "@/components/Welfare/Project/Listener/VolumePeriodDialog";
import WelfareProjectListenerVolumeTotalDialog
  from "@/components/Welfare/Project/Listener/VolumeTotal";
import dataWelfare from "@/data/welfare";
import {PrismEditor} from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";
import GraphHighcharts from "@/components/Graph/Highcharts";
import Highcharts from "highcharts";

export default {
  name: "PageWelfareTeamListener",
  components: {
    GraphHighcharts,
    WelfareProjectListenerErrorGroupDialog,
    WelfareProjectListenerSuccessFailRatioDialog,
    WelfareProjectListenerVolumePeriodDialog,
    WelfareProjectListenerVolumeTotalDialog,
    PageLoader,
    PrismEditor,
  },
  data() {
    return {
      errorGroup: null,
      listener: null,
      project: null,
      successFailRatio: null,
      teamList: null,
      volumePeriod: null,
      volumeTotal: null,
    };
  },
  computed: {
    dialogComponent() {
      return `WelfareProjectListener${this.listener.type.charAt(0).toUpperCase() +
      this.listener.type.slice(1)}Dialog`;
    },
    graphData() {
      const chart = {
        title: {text: ""},
        credits: {enabled: false},
      }
      switch (this.listener.type) {
        case 'successFailRatio':
          return {
            ...chart,
            chart: {
              type: "column",
            },
            xAxis: {
              categories: this.successFailRatio.map(v => new Date(v.timestamp.seconds * 1000)),
              labels: {
                formatter: function() {
                  return Highcharts.dateFormat('%e/%m/%y %H:%M', this.value);
                }
              }
            },
            yAxis: {
              title: '',
            },
            series: [{
              name: 'Success',
              color: 'green',
              data: this.successFailRatio.map(v => v.success ? 1 : null)
            },{
              name: 'Fail',
              color: 'red',
              data: this.successFailRatio.map(v => v.success ? null : 1)
            }]
          };
        case 'volumePeriod':
        case 'volumeTotal':
          return {
            ...chart,
            xAxis: {
              categories: this.volumePeriod.map(v => new Date(v.timestamp.seconds * 1000)),
              labels: {
                formatter: function() {
                  return Highcharts.dateFormat('%e/%m/%y %H:%M', this.value);
                }
              }
            },
            yAxis: {
              title: '',
            },
            series: [{
              name: this.listener.name,
              data: this.volumePeriod.map(v => v.amount)
            }]
          };
        default:
          return chart;
      }
    },
    implementationBody() {
      switch (this.listener.type) {
        case 'errorGroup':
          return this.implementationBodyJson({
            errorGroup: {
              uniqueId1: 'Error message 1',
              uniqueId2: 'Error message 2',
            }
          });
        case 'successFailRatio':
          return this.implementationBodyJson({success: true});
        case 'volumePeriod':
        case 'volumeTotal':
          return this.implementationBodyJson({amount: 123});
        default:
          return this.implementationBodyJson({});
      }
    },
    implementationBodyAlternative() {
      switch (this.listener.type) {
        case 'volumePeriod':
          return this.implementationBodyJson({
            multi: [
              {date: '2000-01-01', amount: 123},
              {date: '2000-01-02', amount: 456},
            ]
          });
        default:
          return false;
      }
    },
    listenerID() {
      return this.$route.params.listenerID;
    },
    listenerTypeFriendly() {
      return dataWelfare.listenerTypeFriendly(this.listener);
    },
    projectID() {
      return this.listener ? this.listener.projectId : null;
    },
    stalePeriod() {
      if (!this.listener.stalePeriod) {
        return 'Unknown';
      }
      if (!this.listener.stalePeriod.includes('-')) {
        return `Invalid: ${this.listener.stalePeriod}`;
      }
      let [amount, type] = this.listener.stalePeriod.split('-');

      if (amount > 1) {
        type += 's';
      }

      return `After ${amount} ${type}`;
    },
    teamName() {
      const team = this.teamList.find(t => t.id === this.project.team);
      return team ? team.name : `Unknown (${this.project.team})`;
    },
  },
  watch: {
    listener() {
      if (!this.project || this.project.id !== this.projectID) {
        this.$bind("project", db.collection("welfare-project").doc(this.projectID));
      }
    },
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.json);
    },
    implementationBodyJson(additional) {
      const body = {
        projectCode: this.project.code,
        listenerCode: this.listener.code,
        ...additional,
      }
      return JSON.stringify(body, null, ' ')
    },
  },
  firestore() {
    return {
      errorGroup: db.collection("welfare-listener").doc(this.listenerID).collection("errorGroup").where("status", "==", "active"),
      listener: db.collection("welfare-listener").doc(this.listenerID),
      successFailRatio: db.collection("welfare-listener").doc(this.listenerID).collection("successFailRatio").orderBy("timestamp", "desc").limit(50),
      volumePeriod: db.collection("welfare-listener").doc(this.listenerID).collection("volumePeriod").orderBy("timestamp", "desc").limit(50),
      volumeTotal: db.collection("welfare-listener").doc(this.listenerID).collection("volumeTotal").orderBy("timestamp", "desc").limit(50),
      teamList: db.collection("welfare-team"),
    };
  },
};
</script>

<style scoped>

</style>
