<template>
  <page-upsert-dialog
    ref="dialog"
    :error="error"
    :id="id"
    :mode="mode"
    :title="title"
    @clear="clear"
    @save="save"
  >
    <template v-slot:activator="{open}">
      <slot name="activator" :open="open" />
    </template>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Listener Name"
      />
      <v-text-field
        v-if="mode === 'create'"
        v-model="code"
        :rules="codeRules"
        label="Listener Project Unique Code"
      />
      <slot

      />
      <v-select
        v-model="stalePeriod"
        :items="stalePeriodItems"
        :rules="stalePeriodRules"
        label="Stale alert after"
      />
    </v-form>
  </page-upsert-dialog>
</template>

<script>
import {db} from "@/firestore";
import PageUpsertDialogBase from "@/components/Page/UpsertDialog.base";
import _ from "lodash";

export default {
  extends: PageUpsertDialogBase,
  name: "WelfareProjectListenerBaseDialog",
  props: {
    id: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelField: ["code", "name", "stalePeriod"],
      code: "",
      codeTaken: false,
      codeRules: [
        v => !!v || "Please enter a listener code",
        v => !!v.match(/^[A-Z]+[A-Z-]*[A-Z]+$/) || "Listener code must contain upper case letters and dashes only",
        v => this.codeTaken === true || this.codeTaken,
      ],
      name: "",
      nameTaken: false,
      nameRules: [
        v => !!v || "Please enter a listener name",
        v => this.nameTaken === true || this.nameTaken,
      ],
      stalePeriod: "",
      stalePeriodItems: [
        {value: "1-hour", text: "1 Hour"},
        {value: "2-hour", text: "2 Hours"},
        {value: "6-hour", text: "6 Hours"},
        {value: "12-hour", text: "12 Hours"},
        {value: "1-day", text: "1 Day"},
        {value: "2-day", text: "2 Days"},
        {value: "7-day", text: "7 Days"},
        {value: "14-day", text: "14 Days"},
        {value: "31-day", text: "31 Days"},
      ],
      stalePeriodRules: [
        v => !!v || "Please select a stale period",
      ],
      team: "",
      teamRules: [
        v => !!v || "Please select a team",
      ],
    };
  },
  computed: {
    modelData() {
      const data = {projectId: this.projectId, type: this.type};
      for (const field of this.modelField) {
        data[field] = this[field];
      }
      if (this.mode !== 'create') {
        delete data.code;
      } else {
        data.status = 'not-fired';
      }
      const slotData = this.$scopedSlots.default()[0].context.$data;
      for (const field of slotData.modelField) {
        data[field] = slotData[field];
      }
      return data;
    },
  },
  created() {
    this.title = this.dialogTitle;
  },
  watch: {
    code: _.debounce(function(value) {
      const firestore = db;
      this.codeTaken  = true;
      if (this.$refs.form && value !== "") {
        this.$refs.form.validate();
        firestore.collection("welfare-listener").
          where("code", "==", value.trim()).
          get().
          then((existing) => {
            const doc = existing.docs.filter(d => d.data().projectId === this.projectId);
            if (doc.length && doc.id !== this.id) {
              this.codeTaken = "A listener with this code already exists";
              this.$refs.form.validate();
            }
          });
      }
    }, 250),
    name: _.debounce(function(value){
      const firestore = db;
      this.nameTaken  = true;
      if (this.$refs.form && value !== "") {
        this.$refs.form.validate();
        firestore.collection("welfare-listener").
          where("name", "==", value.trim()).
          get().
          then((existing) => {
            const doc = existing.docs.filter(d => d.data().projectId === this.projectId);
            if (doc.length && doc.id !== this.id) {
              this.nameTaken = "A listener with this name already exists";
              this.$refs.form.validate();
            }
          });
      }
    }, 250),
  },
  methods: {
    create(resolve) {
      db.collection("welfare-listener").
        add(this.modelData).
        then((document) => {
          resolve(document.id)
        });
    },
    edit(resolve) {
      db.collection("welfare-listener").
        doc(this.id).
        update(this.modelData).
        then(() => {
          resolve(this.id)
        });
    },
    lookup(resolve, reject) {
      db.collection("welfare-listener").
        doc(this.id).
        get().
        then((data) => {
          if (!data.exists) {
            reject();
          } else {
            const slotData = this.$scopedSlots.default()[0].context.$data;
            for (const key of Object.keys(data.data())) {
              if (slotData.modelField.includes(key)) {
                slotData[key] = data.data()[key];
              }
            }
            resolve(data.data());
          }
        });
    }
  },
};
</script>
