<template>
    <v-card>
        <v-card>
            <v-data-table
                :headers="headers"
                :items="cupritArray"
                :loading="!cupritArray"
                dense
                class="pa-5 mb-5"
            />
            <v-card-actions>
                <v-btn color="blue darken-1" text :to="'/code/list'"> Back to main list </v-btn>
          </v-card-actions>
        </v-card>
    </v-card>
</template>
  
<script>
import { db } from '@/firestore';
export default {
    name: 'CodeList',
    data() {
        return {
            cupritArray: [],
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                },
                { text: 'Count', value: 'count' },
            ],
            mergePermissionsLogs: []
        };
    },
    async created() {
        const res = await db.collection('code').doc('github').collection('mergePermissionsLogs').get();
        this.mergePermissionsLogs = res.docs.map(item=>item.data())

        const culpritMap = {}

        for (let log of this.mergePermissionsLogs) {
            const culprit = log.username ||  log.userName
            culpritMap[culprit] = culpritMap[culprit]===undefined ? 1 : culpritMap[culprit] + 1
        }

        const cupritArray = []
        for (const [key, value] of Object.entries(culpritMap)) {
            cupritArray.push({name: key, count: value})
        }
        this.cupritArray = cupritArray.sort((a,b)=>b.count-a.count)
    },
};
</script>
  
<style scoped>
</style>
  