<template>
  <v-card @click:outside="$emit('close')">
    <v-card v-if="!loader">
      <v-card-title> Sprint Item details </v-card-title>
      <div class="pa-5">
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Item name</v-list-item-title>
              <v-list-item-subtitle><a :href="`https://fundamental-media.monday.com/boards/435013974/pulses/${mondayItem && mondayItem.id}`" class="ml-1" target="_blank">{{ mondayItem && mondayItem.name }}</a></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Sprint Name</v-list-item-title>
              <v-list-item-subtitle>{{ mondayItem && mondayItem.sprintName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Item id</v-list-item-title>
              <v-list-item-subtitle>{{ mondayItem && mondayItem.id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Item tags</v-list-item-title>
              <v-list-item-subtitle v-if="mondayItem && mondayItem.tags">{{ mondayItem && mondayItem.tags }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="mondayItem && !mondayItem.tags"><v-alert type="error">Tags missing: please add a tag to the item so that it works properly</v-alert></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>People</v-list-item-title>
              <v-list-item-subtitle>{{ mondayItem && mondayItem.people }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div>
          <v-btn
            :disabled="!selectedItem"
            @click="dialogBranch = true"
            class="mb-2 mt-2">
            Create a Branch
          </v-btn>
        </div>
        <div>
          <div class="pa-3">
            Related Branches
          </div>
          <v-data-table :headers="headers" :items="selectedItem && selectedItem.filteredBranches" dense class="elevation-1 pa-5 mb-5">
            <template v-for="(h,i) in headers" v-slot:[`header.${h.value}`]="{}">
              <v-tooltip top :key="i">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{h.text}}</span>
                </template>
                <span>{{h.tooltip}}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.repoName="{ item }">
              <img src="@/assets/icons/repo.svg" alt="" />
              {{item.repoName}}
            </template>

            <template v-slot:item.name="{ item }">
              <div class="d-flex">
                <a :href="item.prStatus.url || item._links" target="_blank">{{ item.name }}</a>
                <v-btn class="button-tooltip-container ml-3" x-small @click="copyToClipboard(item)" >
                  <img src="@/assets/icons/copy-clipboard.svg"/>
                  <span id="custom-tooltip">copied!</span>
                </v-btn>
                <v-btn x-small class="ml-3" @click="copyToGithubDesktop(item)">
                  <img src="@/assets/icons/github-desktop.svg"/>
                </v-btn>
              </div>
            </template>

            <template v-slot:item.createPr="{ item }">
              <v-btn x-small @click="createPR(item)" style="cursor: pointer" class="ml-2">
                <img src="@/assets/icons/create-pull-request.svg"   />
              </v-btn>
            </template>

            <template v-slot:item.prStatus="{ item }">
              <div v-if="item.prStatus.state" >
                <div v-if="!!item.prStatus.merged_at" class="status merged"><img  src="@/assets/icons/merged.svg" alt="" /> PR merged in</div>
                <div v-else-if="item.prStatus.state == 'open'" class="status open"><img src="@/assets/icons/pr.svg" alt="" />PR still open (not merged yet)</div>
                <div v-else src="" class="status"><img src="@/assets/icons/closed.svg"  alt="closed" />PR closed</div>
                <div class="status issues" v-if="item.prStatus.state == 'open' && !item.prStatus.mergeable"><img  src="@/assets/icons/issues.svg" alt="" />PR cannot be merged (conflict or requested changes)</div>
                <div>
                  <div v-if="item.prStatus.state == 'open' && !item.prStatus.mergeable">
                    <v-btn v-if="!(currentUser && currentUser.merge_permission)" @click="requestMergePermission" :loading="loading" small>
                      <img src="@/assets/icons/superuser.svg" alt="" /> Merge Permission
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-else>
                No PR at the moment
              </div>
            </template>
            <template v-slot:item.buildStatus="{ item }">
              <div v-if="item.buildStatus && item.buildStatus[0].commitSha">
                <div v-for="build in item.buildStatus" :key="build.commitSha">
                  <div>
                    <v-btn small :href="build.logUrl" target="_blank" :color="build.status == 'SUCCESS'? 'success': 'error'" >
                      <img
                        v-if="build.status == 'SUCCESS'"
                        src="@/assets/icons/success.svg"
                        alt=""
                      />
                      <img
                        v-if="build.status == 'TIMEOUT'"
                        src="@/assets/icons/timeout.svg"
                        alt=""
                      />
                      <span v-if="build.target" class="ml-1">Target: {{build.target}}</span>
                    </v-btn>
                    <div v-if="build.finishTime" class="ml-1">Last Built: {{moment(build.finishTime).fromNow()}}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                No Related Builds detected
              </div>
            </template>
          </v-data-table>
        </div>
      </div>

      <v-card-actions>
        <v-btn color="blue darken-1" text :to="'/code/list'"> Back to main list </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-circular
      v-else
      indeterminate
      color="primary"
    ></v-progress-circular>
    <new-branch-dialog
      :dialog="dialogBranch"
      :reposItems="filteredReposItems"
      :mondayItem="mondayItem"
      @close="dialogBranch = false"
      @closing="dialogBranch = false;loader =true;"
    />
  </v-card>
</template>

<script>
  import NewBranchDialog from '@/components/Code/NewBranchDialog';
  import { db, auth } from '@/firestore';
  import { mapGetters } from 'vuex';
  import _ from 'lodash';
  import moment from "moment";
  import code from "@/model/code/Code"

  export default {
    name: 'CodeItem',
    components: {
      NewBranchDialog,
    },
    data() {
      return {
        moment,
        loader: false,
        dialogBranch: false,
        dialogPR: false,
        reposItems: [],
        headers: [
          { text: 'Repo Name', value: 'repoName', tooltip: "This is the repo this branch belongs to" },
          { text: 'Branch Name', value: 'name', tooltip: "Branch Name" },
          { text: 'Create a PR', value: 'createPr', tooltip: "Button to create a PR"},
          { text: 'PR status', value: 'prStatus', tooltip: "Info regarding whether the PR is open, the branch being merged or has issues that prevents it from being merged" },
          { text: 'Build status', value: 'buildStatus', tooltip: "The status of the last build for this branch and repo" },
        ],
        branches: [],
        selectedItem: undefined,
        loading: false,
        currentUser: undefined
      };
    },
    async created() {
      await this.$store.dispatch('initialise');
      if(!localStorage.getItem('gh_token')){
        await this.$store.dispatch('gh_setup')
      }

      // retrieving user
      const res1 = await db.collection('code').doc('github').collection('developerNames').get();
      this.developerNames = res1.docs.map(item=>item.data())

      this.currentUser = this.developerNames.find(dev=>dev.name.toLowerCase() === auth.currentUser.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
    },
    watch: {
      async mondayItems(newValue, oldValue) {
        if (newValue && newValue.length) {
          const mondayItem = newValue.find(item => item.id === this.$route.params.itemId);
          this.selectedItem = mondayItem
        }
      },
      async mondayItem(newValue, oldValue){
        if (newValue && newValue.branch) {
          this.loader = false
        }
      }
    },
    methods: {
      async createPR(item){
        const res = await db.collection('code').doc('github').collection('reposNames').doc(item.repoName).get()
        const result = await res.data()
        const url = `https://github.com/FundamentalMedia/${item.repoName}/compare/${result.default_branch || 'master'}...${item.name}?expand=1`
        window.open(url);
      },
      copyToClipboard(item){
        let text = `${item.name}`;
        navigator.clipboard.writeText(text).then(function() {
          document.getElementById("custom-tooltip").style.display = "inline"
          setTimeout(function() {document.getElementById("custom-tooltip").style.display = "none"}, 1000);
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },
      copyToGithubDesktop(item){
          let urlRedirection = `x-github-client://openRepo/https://github.com/FundamentalMedia/${item.repo}?branch=${item.name}`
          setTimeout( () => window.location.href = urlRedirection, 500);
      },
      getTags(mondayItem){
        const lsTags = []
        let fixedTags = ["deltaTag/alphix", "amx/alphix", "shared/library", "group/site"]
        for (const itemTag of mondayItem && mondayItem.tags && mondayItem.tags.split(',')) {
          const lowerCaseItem = itemTag.toLowerCase().trim()
          if(fixedTags.includes(lowerCaseItem)){
              lsTags.push(lowerCaseItem);
          }else{
              lsTags.push(lowerCaseItem.split('/')[1]);
          }
        }
        return lsTags
      },
      async requestMergePermission(){
        try {
          this.loading = true
          const result = await code.mergeTeam()
          this.loading = false
          this.$notify({
            type: result.data === 'Permission Granted' ? 'success' : 'warning',
            title: result.data,
          });
          const myTimeout = setTimeout(()=>{window.location.reload();}, 2500);
        } catch (err) {
          console.log(err)
          this.loading = false
          this.$notify({
            type: "error",
            text: `There has been an error: ${err}`,
          });
        }
      },
    },
    computed: {
      ...mapGetters({ mondayItems: 'getMondayItems', builds: "getBuilds" }),
      filteredReposItems() {
        if (this.mondayItem && this.mondayItem.tags && this.mondayItem.tags.split(',')) {
          let lsTags = this.getTags(this.mondayItem)
          return (
            this.reposItems &&
            this.reposItems.filter(item => {
              return _.intersection(item.tag && item.tag.split(','), lsTags).length;
            })
          );
        } else {
          return this.reposItems;
        }
      },
      mondayItem() {
        return this.mondayItems.find(item => item.id === this.$route.params.itemId);
      },
    },
    firestore() {
      return {
        reposItems: db
          .collection('code')
          .doc('github')
          .collection('reposNames')
      };
    }
  };
</script>

<style scoped>
.status{
  padding: 2px 5px;
  margin: 2px;
  border-radius: 5px;
  width: fit-content;
}
.issues{
  background-color:#ff5252 ;
}
.merged{
  background-color:#4caf50;
}
.open{
  background-color: #fb8c00;
}
#custom-tooltip {
    display: none;
    margin-left: 5px;
    padding: 3px 7px;
    background-color: #fff;
    border-radius: 4px;
    color: #000000df;
}
</style>
