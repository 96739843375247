<template>
  <welfare-project-listener-base-dialog
    :id="id"
    :project-id="projectId"
    dialog-title="Error Group"
    type="errorGroup"
  >
    <template v-slot:activator="{open}">
      <slot :open="open" />
    </template>
    <div>
    </div>
  </welfare-project-listener-base-dialog>
</template>

<script>
import WelfareProjectListenerBaseDialog from "@/components/Welfare/Project/Listener/BaseDialog";

export default {
  components: {WelfareProjectListenerBaseDialog},
  name: "WelfareProjectListenerErrorGroupDialog",
  props: {
    id: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelField: [],
    };
  },
};
</script>
