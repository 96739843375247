<template>
  <v-card>
    <v-card-title>
      Welfare Team List
      <v-spacer/>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        hide-details
      />
      <v-spacer/>
      <welfare-team-upsert-dialog>
        <template v-slot="{open}">
          <v-btn
            color="green"
            dark
            @click="open"
          >
            <v-icon class="mr-2" small>fal fa-plus</v-icon>
            Add Team
          </v-btn>
        </template>
      </welfare-team-upsert-dialog>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="team"
      :search="search"
    >
      <template v-slot:item.view="{ item }">
        <v-btn
          small
          text
          :to="{
                  name: 'welfare-team-detail',
                  params: { teamID: item.id }
                }">
          View Team
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {db} from "@/firestore";
import dataWelfare from "@/data/welfare";
import WelfareTeamUpsertDialog from "@/components/Welfare/Team/UpsertDialog";

export default {
  name: "PageWelfareTeamList",
  components: {WelfareTeamUpsertDialog},
  data() {
    return {
      headers: [
        {text: "View", value: "view", width: 50, sortable: false},
        {text: "Name", value: "name"},
      ],
      search: "",
      team: [],
    };
  },
  firestore: {
    team: db.collection("welfare-team"),
  },
};
</script>
