<template>
  <page-upsert-dialog
    ref="dialog"
    :error="error"
    :mode="mode"
    :title="title"
    @clear="clear"
    @save="save"
  >
    <template v-slot:activator="{open}">
      <slot :open="open" />
    </template>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Team Name"
      />
    </v-form>
  </page-upsert-dialog>
</template>

<script>
import {db} from "@/firestore";
import PageUpsertDialogBase from "@/components/Page/UpsertDialog.base";

export default {
  extends: PageUpsertDialogBase,
  name: "WelfareTeamUpsertDialog",
  data() {
    return {
      modelField: ["name"],
      name: "",
      nameRules: [
        v => !!v || "Please enter a team name",
      ],
      title: "Team",
    };
  },
  methods: {
    create(resolve) {
      db.collection("welfare-team").
        add(this.modelData).
        then((document) => {
          resolve(document.id)
        });
    },
    edit(resolve) {
      db.collection("welfare-team").
        doc(this.id).
        update(this.modelData).
        then(() => {
          resolve(this.id)
        });
    },
    lookup(resolve, reject) {
      db.collection("welfare-team").
        doc(this.id).
        get().
        then((data) => {
          if (!data.exists) {
            reject();
          } else {
            resolve(data.data());
          }
        });
    },
  },
};
</script>
