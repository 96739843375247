import { render, staticRenderFns } from "./VolumeTotal.vue?vue&type=template&id=3ab321ef&"
import script from "./VolumeTotal.vue?vue&type=script&lang=js&"
export * from "./VolumeTotal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports